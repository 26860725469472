import { MutationTree } from 'vuex'
import { OverviewState } from './state'

export interface OverviewMutations {
    ['setAllCredentials'](state: OverviewState, credentials:  Array<Record<string, any>>): void;
    ['setAllOAuth'](state: OverviewState, OAuth:  Array<Record<string, any>>): void;
    ['setAllFlows'](state: OverviewState, allFlows:  Array<Record<string, any>>): void;
    ['setStatistics'](state: OverviewState, statistics: Record<string, any>): void;
    ['setSubscription'](state: OverviewState, subscription: Record<string, any>): void;
    ['setAllEmails'](state: OverviewState, allEmails: Array<string> | boolean): void;
    ['setAllEndpoints'](state: OverviewState, allEndpoints: Array<Record<string, any>>): void;
}
export const overviewMutations: MutationTree<OverviewState> & OverviewMutations = {
    ['setAllCredentials'](state: OverviewState, credentials: Array<Record<string, any>>): void {
        state.allCredentials = credentials;
    },
    ['setAllOAuth'](state: OverviewState, OAuth: Array<Record<string, any>>): void {
        state.allOAuth = OAuth;
    },
    ['setAllFlows'](state: OverviewState, allFlows: Array<Record<string, any>>): void {
        state.allFlows = allFlows;
    },
    ['setStatistics'](state: OverviewState, statistics: Record<string, any>): void {
        state.statistics = statistics;
    },
    ['setSubscription'](state: OverviewState, subscription: Record<string, any>): void {
        state.subscription = subscription;
    },
    ['setAllEmails'](state: OverviewState, allEmails: Array<string> | boolean): void {
        state.allEmails = allEmails;
    },
    ['setAllEndpoints'](state: OverviewState, allEndpoints: Array<Record<string, any>>): void {
        state.allEndpoints = allEndpoints;
    },
}