export interface OverviewState {
    allCredentials: Array<Record<string, any>> | null;
    allOAuth: Array<Record<string, any>> | null;
    allFlows: Array<Record<string, any>> | null;
    allUsersInOrganization: Record<string, any> | null;
    statistics: Record<string, any> | null;
    subscription: Record<string, any> | null;
    allEmails: Array<string> | boolean | null;
    allEndpoints: Array<Record<string, any> > | null;
}

export const overviewState: OverviewState = {
    allCredentials: null,
    allOAuth: null,
    allFlows: null,
    allUsersInOrganization: null,
    statistics: null,
    subscription: null,
    allEmails: null,
    allEndpoints: null
}